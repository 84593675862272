import React, { Component, Fragment } from "react";
import Sidebar from "../components/common/Sidebar";
import Footer from "../components/common/Footer";
import Topbar from "../components/common/Topbar";
import PageTitle from "../components/home/PageTitle";
import DashboardKPI from "../components/home/DashboardKPI";


class HomePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <Fragment>
        <div id="wrapper">

          <Sidebar />

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">

              <Topbar />

              <div className="container-fluid">

                <PageTitle Title="Dashboard" />
                <DashboardKPI />

              </div>

            </div>

            <Footer />
          </div>

        </div>        

      </Fragment>
    );
  }
}

export default HomePage;
