import React, { Component, Fragment } from "react";
import { Route, Switch  } from "react-router";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import URLPage from "../pages/Master/URLPage";
import TravelCircuitPage from "../pages/Master/TravelCircuitPage";
import SpecialistPage from "../pages/Master/SpecialistPage";
import SearchLeadPage from "../pages/Transaction/SearchLeadPage";
import LoginPage from "../pages/Access/LoginPage";
import RegisterPage from "../pages/Access/RegisterPage";
import ForgotPasswordPage from "../pages/Access/ForgotPasswordPage";
import ProtectedRoute from "../pages/ProtectedRoutes";


class AppRoute extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <LoginPage {...props} key={Date.now()} />}
          />
          <ProtectedRoute
            exact
            path="/home"
            component={HomePage}
          />
          <Route
            exact
            path="/about"
            render={props => <AboutPage {...props} key={Date.now()} />}
          />
          <ProtectedRoute
            exact
            path="/url"
            component={URLPage}
          />
          <ProtectedRoute
            exact
            path="/tc"
            component={TravelCircuitPage}
          />
          <ProtectedRoute
            exact
            path="/spl"
            component={SpecialistPage}
          />
          <ProtectedRoute
            exact
            path="/sl"
            component={SearchLeadPage}
          />         
          <Route
            exact
            path="/login"
            render={props => <LoginPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/register"
            render={props => <RegisterPage {...props} key={Date.now()} />}
          />
          <Route
            exact
            path="/forgotpwd"
            render={props => <ForgotPasswordPage {...props} key={Date.now()} />}
          />
          {/* <ProtectedRoute
            exact
            path="/provider-profile"
            component={ProviderProfilePage}
          /> */}
          
        </Switch>
      </Fragment>
    );
  }
}

export default AppRoute;
