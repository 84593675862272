class ApiURL {
  ////==========================================================================
  ////Base URL Section
  ////==========================================================================
  ////Local
  //static BaseURL = "https://localhost:7268";

  ////Production
  //static BaseURL = "http://103.168.18.29";
  static BaseURL = "https://service.adorablevacation.com";  

  ////==========================================================================
  ////Image URL Section
  ////==========================================================================
  static BaseImageURL = this.BaseURL + "/uploadfiles/images";
  static HomeBannerImageURL = this.BaseImageURL + "/HomeBanner";
  static HomeDestinationImageURL = this.BaseImageURL + "/HomeDestination";
  static PackagesWeOfferImageURL = this.BaseImageURL + "/HomePackageWeOffer";
  static TestimonialClientImageURL = "https://mdbcdn.b-cdn.net/img/Photos/Avatars";
  //static TestimonialClientImageURL = this.BaseImageURL + "/Testimonial";
  static HotPackageImageURL = this.BaseImageURL + "/HotPackages";
  static PackageTopBannerImageURL = this.BaseImageURL + "/Packages/TopBanners";
  static PackageDestinationBannerImageURL = this.BaseImageURL + "/Packages/Destinations";
  static HotelImageURL = this.BaseImageURL + "/Packages/Hotels";


  /*  static HomeServiceImageURL = this.BaseImageURL + "/HomeService";
   static BsetOfferImageURL = this.BaseImageURL + "/BestOffer";
   static PopularServiceImageURL = this.BaseImageURL + "/PopularService";
   static MarketplaceImageURL = this.BaseImageURL + "/Marketplace";
   static OtherServiceImageURL = this.BaseImageURL + "/OtherService";
    */

  ////====================================================================
  ////API URL Section=======
  ////===========================================================================
  static SaveCustomerEnquery = this.BaseURL + "/api/customer/saveCustomerEnquery";
  static GetAllOriginCities = this.BaseURL + "/api/customer/getAllOriginCities";

  //URL API's
  static GetAllURL = this.BaseURL + "/api/url/getAllURL";
   //static GetURLById = this.BaseURL + "/api/url/getURLById/{urlId}";
  static GetURLById(urlId) {
    return this.BaseURL + "/api/url/getURLById/" + urlId;
  }
  static SaveURL = this.BaseURL + "/api/url/saveURL";
  static DeleteURL = this.BaseURL + "/api/url/deleteURL";

  //TravelCircuit API's
  static GetAllTC = this.BaseURL + "/api/tc/getAllTC";
  static GetTCById(tcId) {
    return this.BaseURL + "/api/tc/getTCById/" + tcId;
  }
  static SaveTC = this.BaseURL + "/api/tc/saveTC";
  static DeleteTC = this.BaseURL + "/api/tc/deleteTC";

  //Specialist API's
  static GetAllSPL = this.BaseURL + "/api/specialist/getAllSPL";
  static GetSPLById(splId){
    return this.BaseURL + "/api/specialist/getSPLById/" + splId;
  }
  static SaveSPL = this.BaseURL + "/api/specialist/saveSPL";
  static DeleteSPL = this.BaseURL + "/api/specialist/deleteSPL";
  
  //Lead API's
  static GetLeadSrcTypes=this.BaseURL+"/api/lead/getAllLeadSrcTypes";
  static GetLeadStatus=this.BaseURL+"/api/lead/getAllLeadStatus";
  static GetAllLeadData = this.BaseURL + "/api/lead/getLeadData";
  static GetLeadDataById(leadId){
    return this.BaseURL + "/api/lead/getLeadData/"+leadId;
  }
  static SaveLeadData = this.BaseURL + "/api/lead/saveLead";
  static DeleteLeadData = this.BaseURL + "/api/lead/delete";
  static GetLeadKPIData = this.BaseURL + "/api/lead/getLeadKPIData";

  //Report API's
  static DownloadExcelData=this.BaseURL + "/api/report/getReportLeadData"

  //Account API's
  ////static AdminLogin = this.BaseURL + "/api/account/adminLogin";
  static UserLogin = this.BaseURL + "/api/account/userLogin";
  static ChangePassword = this.BaseURL + "/api/account/changePwd";







  
  /* static CustomerRegistration = this.BaseURL + "/api/customer/customerRegistration";
  static OTPVerification = this.BaseURL + "/api/customer/validateOTP";
  static ResendOTP = this.BaseURL + "/api/customer/resendOTP";
  static GetUserProfile = this.BaseURL + "/api/customer/getCustomerProfile";
  static CustomerLogin = this.BaseURL + "/api/customer/customerLogin";
  static GetAllProviderDataList = this.BaseURL + "/api/provider/getAllProviderDataList";
  static ProviderRegistration = this.BaseURL + "/api/provider/providerRegistration"; */

  ////============================================================================
  /* static CategoryImageURL = this.BaseImageURL + "/category/";
  static SubCategoryImageURL = this.BaseImageURL + "/subcategory/";
  static ProductImageURL = this.BaseImageURL + "/product/";
  static ProductOriginalImageURL = this.BaseImageURL + "/product/original/";
  static ProductCartImageURL = this.BaseImageURL + "/product/thumb/cart_img/";
  static ProductImage1URL = this.BaseImageURL + "/product/thumb/img_1/";
  static ProductImage2URL = this.BaseImageURL + "/product/thumb/img_2/";
  static ProductImage3URL = this.BaseImageURL + "/product/thumb/img_3/";
  static ProductImage4URL = this.BaseImageURL + "/product/thumb/img_4/";
  static ProductImage5URL = this.BaseImageURL + "/product/thumb/img_5/";

  static BannerImageURL = this.BaseImageURL + "/banner/";
  static UserImageURL = this.BaseImageURL + "/user/";

  static BrandImageURL = this.BaseImageURL + "/brand/";
  static LoginImageURL = this.BaseImageURL + "/login/";

  static GetLocationDetails = this.BaseURL + "/api/homedata/getAllLocation";

  static CreateOTP = this.BaseURL + "/api/authentication/createOTP";


  static GetHomeSliderData = this.BaseURL + "/api/homedata/getAllHomeSliderData";
  static GetAllCategoryData = this.BaseURL + "/api/homedata/getAllCategoryData";
  static GetAllHomeInfo = this.BaseURL + "/api/homedata/getAllHomeInfo";
  static AddItemToCart = this.BaseURL + "/api/cart/addItemToCart";
  static RemoveCartItem = this.BaseURL + "/api/cart/removeCartItem";
  static GetCartListCount = this.BaseURL + "/api/cart/getCartListCount";
  static GetCartDetails = this.BaseURL + "/api/cart/getCartDetails";
  static UpdateCartLineItem = this.BaseURL + "/api/cart/updateCartLineItem";
  static GetAllProductWeights = this.BaseURL + "/api/product/getAllProductWeights";
  static GetShippingDetails = this.BaseURL + "/api/checkout/getShippingDetails";
  static SaveOrder = this.BaseURL + "/api/order/saveOrder";
  static CancelOrder = this.BaseURL + "/api/order/cancelOrder";
  static OrderHistory = this.BaseURL + "/api/order/orderHistory";

  static ProductDetails(ProductCode) {
    return this.BaseURL + "/api/product/productDtls/" + ProductCode;
  }
  static GetSimilarProducts(ProductCode, Cat1Id) {
    return this.BaseURL + "/api/product/getSimilarProducts/" + ProductCode + "/" + Cat1Id;
  }
  static ProductListBySearch(searchKey) {
    return this.BaseURL + "/api/product/productListBySearch/" + searchKey;
  }
  static ProductListByCategory(Cat1Name) {
    return this.BaseURL + "/api/product/productListByCategory/" + Cat1Name;
  }
  static ProductListBySubcategory(Cat1Name, Cat2Name) {
    return this.BaseURL + "/api/product/productListBySubcategory/" + Cat1Name + "/" + Cat2Name;
  } */


}

export default ApiURL;
