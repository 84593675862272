import React, { Component } from 'react';
import { registerAllModules } from "handsontable/registry";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";

class DashboardKPI extends Component {
    constructor(props) {
        super(props);
        // register Handsontable's modulesy
        registerAllModules();
        this.state = {
            monthlyLeadCount: 0,
            monthlyConvertedLeadCount: 0,
            yearlyLeadCount: 0,
            yearlyConvertedLeadCount: 0,
        };
    }
    //componentDidMount lifecycle event
    componentDidMount() {
        this.getLeadKPIData();
    }

    getLeadKPIData = () => {
        axios
            .get(ApiURL.GetLeadKPIData)
            .then((response) => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    var dataResult = respData.DataResult;
                    console.log(dataResult);
                    this.setState({
                        monthlyLeadCount: dataResult.MonthlyLeadCount,
                        monthlyConvertedLeadCount: dataResult.MonthlyConvertedLeadCount,
                        yearlyLeadCount: dataResult.YearlyLeadCount,
                        yearlyConvertedLeadCount: dataResult.YearlyConvertedLeadCount,
                    })
                } else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
                }
            })
            .catch((error) => {
                cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
            });
    }

    render() {
        return (
            <>
                <div className="row">

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-primary shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                            Lead (Monthly)
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.monthlyLeadCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-success shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                            Converted Lead (Monthly)
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.monthlyConvertedLeadCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-info shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                                            Lead (Yearly)
                                        </div>
                                        <div className="row no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{this.state.yearlyLeadCount}</div>
                                            </div>
                                            <div className="col">
                                                <div className="progress progress-sm mr-2">
                                                    <div className="progress-bar bg-info" role="progressbar"
                                                        style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6 mb-4">
                        <div className="card border-left-warning shadow h-100 py-2">
                            <div className="card-body">
                                <div className="row no-gutters align-items-center">
                                    <div className="col mr-2">
                                        <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                            Converted Lead (Yearly)
                                        </div>
                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{this.state.yearlyConvertedLeadCount}</div>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}

export default DashboardKPI;