import React, { Component } from 'react';
import Login from '../../components/common/Login';

class LoginPage extends Component {
    render() {
        return (
            <>
                <div className="bg-gradient-primary height-100vh">
                    <Login />
                </div>
            </>
        );
    }
}

export default LoginPage;