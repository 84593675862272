import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import validation from '../../validation/validation';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btn: 'Login',
            email: '',
            password: '',
            redirectDashboardStatus: false
        };
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signIn = (e) => {
        e.preventDefault();
        var { email, password } = this.state;
        if (email === '') {
            cogoToast.error("Please enter Email Id!", { position: 'bottom-center' });
        }
        else if (email.length > 0 && !(validation.EmailRegx).test(email)) {
            cogoToast.error("Invalid Email Id!", { position: 'bottom-center' });
        }
        else if (password === '') {
            cogoToast.error("Please enter valid password!", { position: 'bottom-center' });
        }
        else {
            this.setState({ btn: "Processing.." });
            var param = {
                Email: email,
                Pwd: password
            };
            axios.post(ApiURL.UserLogin, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    let dataResult = respData.DataResult;
                    if (dataResult !== null) {
                        LocalStorageHelper.setUser(JSON.stringify(dataResult));
                        this.setState({ btn: "Login", redirectDashboardStatus: true })
                    }
                    else {
                        cogoToast.error('Login failed ! Please contact to your System Administrator', { position: 'bottom-center' });
                    }

                } else if (respData.StatusCode === 400) {
                    this.setState({ btn: "Login" });
                    cogoToast.error(respData.Message, { position: 'bottom-center' });

                } else if (respData.StatusCode === 500) {
                    this.setState({ btn: "Login" });
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                }

            }).catch(error => {
                this.setState({ btn: "Login" });
                cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            })
        }
    }

    redirectToDashboardPage = () => {
        if (this.state.redirectDashboardStatus === true) {
            return <Redirect to={'/home'} />
        }
    }


    render() {
        const loginImgURL = ApiURL.BaseImageURL + "/login.webp";
        return (
            <>
                <div className="container">

                    {/* <!-- Outer Row --> */}
                    <div className="row justify-content-center">
                        <div className="col-xl-10 col-lg-12 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    {/* <!-- Nested Row within Card Body --> */}
                                    <div className="row">
                                        <div className="col-lg-6 d-none d-lg-block bg-login-image">
                                            <img src={loginImgURL} alt="..." width="500" />
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="p-5">
                                                <div className="text-center">
                                                    <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                                </div>
                                                <form className="user">
                                                    <div className="form-group">
                                                        <input
                                                            type="email"
                                                            className="form-control form-control-user"
                                                            name="email"
                                                            placeholder="Enter Email Address..."
                                                            value={this.state.email}
                                                            onChange={this.onChangeHandler}
                                                            autoComplete='false'
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <input
                                                            type="password"
                                                            className="form-control form-control-user"
                                                            name="password"
                                                            placeholder="Password"
                                                            value={this.state.password}
                                                            onChange={this.onChangeHandler}
                                                        />
                                                    </div>
                                                    <Link
                                                        to={"/"}
                                                        className="btn btn-primary btn-user btn-block"
                                                        onClick={this.signIn}
                                                    >
                                                        {this.state.btn}
                                                    </Link>
                                                    <hr />
                                                </form>
                                                <hr />
                                                 <div className="text-center">
                                                    <Link to={"/forgotpwd"} className="small">Forgot Password?</Link>
                                                </div>
                                                <div className="text-center">
                                                    <Link to={"/register"} className="small">Create an Account!</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.redirectToDashboardPage()}
            </>
        );
    }
}

export default Login;