import React, { Component } from 'react';

class RegisterPage extends Component {
    render() {
        return (
            <>
                <h1>Register Page</h1>
            </>
        );
    }
}

export default RegisterPage;