import React, { Component } from 'react';

class Footer extends Component {
    render() {

        return (
            <>
                <footer className="sticky-footer bg-white  " style={{height:"50px"}}>
                    <div className="container my-auto ">
                        <div className="copyright text-center my-auto">
                            <span>Copyright &copy; Your Website 2023</span>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default Footer;