import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
//import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/css/bigSlider.css"
import "./assets/css/sb-admin-2.css";
import "./assets/css/custom.css";
//import "./assets/css/fontawesome.css";
//import 'font-awesome/css/font-awesome.min.css';
//import "./assets/css/animate.min.css";
//import "@szhsin/react-menu/dist/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//import "./assets/css/placeholder-loading.min.css";
import * as serviceWorker from "./serviceWorker";
import LocalStorageHelper from "./LocalStorageHelper/LocalStorageHelper";



// axios.interceptors.request.use((request) => {
//   const token = LocalStorageHelper.getAccessToken();
//   if (token) {
//       request.headers['Authorization'] = 'Bearer ' + token;
//   }
//   // config.headers['Content-Type'] = 'application/json';
//   return request;
// },
// (error) => {
//   Promise.reject(error);
// });

// axios.interceptors.response.use((response) => {
//   return response;
// },
// (error) => {
//   if (error.response.status === 401) {
//       console.log(error);
//       //router.push('/login');
//       //alert('Unauthorized! Please login.');
//       return Promise.reject(error);
//   }
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
