import React, { Component } from 'react';
import ForgetPassword from '../../components/common/ForgetPassword';

class ForgotPasswordPage extends Component {
    render() {
        return (
            <>
                <div className="bg-gradient-primary height-100vh">
                    <ForgetPassword/>
                </div>
            </>
        );
    }
}

export default ForgotPasswordPage;