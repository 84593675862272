import * as localforage from "localforage";
class LocalStorageHelper {

    // Set Get Site Info Footer
    static SetSiteInfoFooter(JSONData){
        localforage.setItem('SiteInfoFooter',JSON.stringify(JSONData));
    }
    static GetSiteInfoFooter(callback){
        localforage.getItem('SiteInfoFooter', function (err, value) {
            if(err){
                callback(null)
            }
            else{
                callback(JSON.parse(value))
            }
        });
    }

    // Set/Get/Remove User
    static setUser(UserData){
        //localStorage.setItem("User",UserData);
        sessionStorage.setItem("User",UserData);
    }
    static getUser(){
        //return  localStorage.getItem("User");
        return  sessionStorage.getItem("User");
    }
    static removeUser(){
        //return  localStorage.removeItem("User");
        return  sessionStorage.removeItem("User");
    }



    // Set/Get/Remove User Mobile
    static setUserMobile(UserMobile){
        localforage.setItem("UserMobile",UserMobile)
    }
    static getUserMobile(){
        return  localforage.getItem("UserMobile")
    }
    static removeUserMobile(){
        return  localforage.removeItem("UserMobile")
    }

    // Set/Get Last Win location
    static SetLastRedirectPath(winlocation) {
        sessionStorage.setItem("winlocation", winlocation)
    }

    static GetLastRedirectPath() {
        return sessionStorage.getItem("winlocation");
    }

    static removeLastRedirectPath() {
        return sessionStorage.removeItem("winlocation");
    }


    static SetRedirectFromDetails(winlocation){
        sessionStorage.setItem("winlocation",winlocation)
    }

    static GetRedirectFromDetails(){
        return sessionStorage.getItem("winlocation");
    }
}
export default LocalStorageHelper;