import React, { Component } from "react";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { registerAllModules } from "handsontable/registry";
import LocalStorageHelper from "../../LocalStorageHelper/LocalStorageHelper";
import "handsontable/dist/handsontable.full.min.css";
import "../../assets/css/custom.css";

export default class TravelCircuit extends Component {
  //constructor lifecycle event
  constructor(props) {
    super(props);
    //register Handsontable's modules
    registerAllModules();
    this.state = {
      showModal: false,
      showDeleteWarnModal: false,
      TCData: [],
      tcId: 0,
      travelCircuitName: '',
      travelDestination: '',
      userId: 0,
      userRole: '',
      isActive: false
    };
  }
  //componentDidMount lifecycle event
  componentDidMount() {
    window.scroll(0, 0);
    var userData = JSON.parse(LocalStorageHelper.getUser());
    if (userData !== null && userData.UserId > 0) {
      this.setState({
        userId: userData.UserId,
        userRole: userData.RoleName,
        displayName: userData.DisplayName
      })
    }
    this.getAllTCData();
  }
  //Data Reload
  handleReload = () => this.getAllTCData();
  //Reset all input fields into the modal
  resetInputFields = () => {
    this.setState({
      showModal: false,
      showDeleteWarnModal: false,
      tcId: 0,
      travelCircuitName: "",
      travelDestination: "",
      isActive: false
    });
  }
  ///Open modal
  openModal = () => this.setState({ showModal: true });
  //Close modal
  closeModal = () => this.setState({ showModal: false });
  //Open Delete warning modal
  openWarnModal = () => this.setState({ showDeleteWarnModal: true });
  //Close Delete warning modal
  closeWarnModal = () => this.setState({ showDeleteWarnModal: false });
  //OnChangeHandler
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Get all data
  getAllTCData = () => {
    axios
      .get(ApiURL.GetAllTC)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          this.setState({ TCData: dataResult });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get data by Id
  getTCById = (tcId) => {
    var self = this;
    axios
      .get(ApiURL.GetTCById(tcId))
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          self.setState({
            tcId: dataResult.TravelCircuitId,
            travelCircuitName: dataResult.TravelCircuitName,
            travelDestination: dataResult.TravelDestination,
            isActive: dataResult.IsActive,
            showModal: true,
          });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Handle Add new Record
  handleAddNew = () => {
    this.resetInputFields();
    this.openModal();
  }
  //Delete data by Id
  handleDelete = () => {
    let param = {
      TravelCircuitId: this.state.tcId,
      IsActive: false
    };
    axios
      .post(ApiURL.DeleteTC, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success("Travel Circuit Deleted Successfully!", { position: "bottom-center" });
          this.resetInputFields();
          this.closeModal();
          this.getAllTCData();
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //save data
  handleSubmit = (e) => {
    e.preventDefault();
    //set the blank validation
    if (!this.state.travelCircuitName || !this.state.travelDestination) {
      cogoToast.error("Please fill all required fields.", { position: "bottom-center" });
      return;
    }
    let param = {
      TravelCircuitId: this.state.tcId,
      travelCircuitName: this.state.travelCircuitName,
      travelDestination: this.state.travelDestination,
      isActive: this.state.isActive
    };
    axios
      .post(ApiURL.SaveTC, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success("Travel Circuit saved Successfully!", { position: "bottom-center" });
          this.resetInputFields();
          this.getAllTCData();
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //render lifecycle event
  render() {
    var self = this;
    const { showModal, tcId, showDeleteWarnModal, TCData } = self.state;
    const travelCircuitListView = TCData.map((tc, i) => (
      <tr key={i} >
        <td data-title="Select" className="text-center">
          <button
            className="btn  btn-primary btn-sm text-white"
            style={{ height: "30px", width: "30px" }}
            onClick={() => self.getTCById(tc.TravelCircuitId)}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        </td>
        <td data-title="TravelCircuit Name">{tc.TravelCircuitName}</td>
        <td data-title="Travel Destination">{tc.TravelDestination}</td>
        <td data-title="Email" align='center'>
          {
            tc.IsActive ?
              <i className="fa-regular fa-square-check" style={{ fontSize: "20px" }}></i> :
              <i className="fa-regular fa-square-uncheck" style={{ fontSize: "20px" }}></i>
          }
        </td>
      </tr>
    ));

    return (
      <>
        {/* ------Start the main content ------------- */}

        <div className="container-fluid ">
          <div className="card px-4 py-4 mb-2 sm:w-full">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                onClick={this.handleReload}
                className="btn btn-info px-3 mx-3"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Refresh"
              >
                <i className="fa-solid fa-arrows-rotate"></i>
              </button>

              <button
                onClick={this.handleAddNew}
                className="btn btn-primary px-3 cursor-pointer"
                data-toggle="tooltip "
                data-bs-placement="top"
                data-bs-title="Add"
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
            <hr className="hr" />

            {/* grid table */}
            <div id="no-more-tables" className="tableContainer" >
              <table className="table table-bordered table-striped table-condensed cf" >
                <thead className="cf bg-primary text-white">
                  <tr>
                    <th className="text-center" style={{ maxWidth: "20px" }}>Select</th>
                    <th className="text-center">TravelCircuit Name</th>
                    <th className="text-center">Travel Destination</th>
                    <th className="text-center">Active</th>
                  </tr>
                </thead>
                <tbody >{travelCircuitListView}</tbody>
              </table>
            </div>
          </div>
        </div>

        {/* <!----------------- modal section----------------> */}
        <div className={showModal === true ? "modal show-modal  modalWrapper" : "modal"}
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title ">
                  {tcId <= 0 ? `Add TravelCircuit` : `Update TravelCircuit`}
                </h1>
                <i
                  className="fa-regular fa-circle-xmark text-danger"
                  data-bs-dismiss="modal"
                  onClick={this.closeModal}
                ></i>
              </div>
              <div className="modal-body">
                <div className="row ">
                  <div className="col">
                    <label
                      htmlFor="tcName"
                      className="form-label text-sm"
                      style={{ fontSize: "15px" }}
                    >
                      Travel Circuit Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Travel Circuit Name"
                      aria-label="tc name"
                      id="tcName"
                      name="travelCircuitName"
                      value={this.state.travelCircuitName}
                      onChange={this.handleChange}
                      requited={true}
                    />
                  </div>
                  <div className="col">
                    <label
                      htmlFor="tdName"
                      className="form-label text-sm"
                      style={{ fontSize: "15px" }}
                    >
                      Travel Destination *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Travel Destination Name"
                      aria-label="td name"
                      id="tdName"
                      name="travelDestination"
                      value={this.state.travelDestination}
                      onChange={this.handleChange}
                      required={true}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer my-3">
                <button
                  type="submit"
                  className="btn btn-success button-box-shadow"
                  onClick={this.handleSubmit}
                >
                  <i className="fa-solid fa-circle-check"></i> Save
                </button>
                {
                  this.state.userRole === 'admin' &&
                  <button
                    type="button"
                    className={`btn btn-danger button-box-shadow curser-pointer ${tcId > 0 ? "" : "disabled"
                      }`}
                    onClick={() => {
                      tcId > 0 && this.openWarnModal();
                    }}
                  >
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow"
                  data-bs-dismiss="modal"
                  onClick={this.closeModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Close
                </button>

              </div>
            </div>
          </div>
        </div>

        {/* <!------DELETE Warning Modal--------> */}
        <div className={showDeleteWarnModal === true ? "modal show-modal " : "modal"}
        >
          <div className="modal-dialog modal-dialog-centered modal-md ">
            <div className="modal-content button-box-shadow">
              <div className="modal-body">
                <div
                  className="alert alert-warning d-flex align-items-center"
                  role="alert"
                >
                  <i className="fa-solid fa-triangle-exclamation"></i>&nbsp;
                  <div>
                    <strong>Are you sure</strong>, you want to <strong>Delete </strong> it?
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger button-box-shadow btn-sm"
                  onClick={this.handleDelete}
                >
                  <i className="fa-solid fa-trash-can"></i> Delete
                </button>

                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow btn-sm"
                  data-bs-dismiss="modal"
                  onClick={this.closeWarnModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Cancel
                </button>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
