import React, { Component } from 'react';
import Sidebar from "../components/common/Sidebar";
import Footer from "../components/common/Footer";
import Topbar from "../components/common/Topbar";
import PageTitle from "../components/home/PageTitle";

class AboutPage extends Component {
    render() {
        return (
            <>
                <div id="wrapper">

                    <Sidebar />

                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">

                            <Topbar />

                            <div className="container-fluid">

                                <PageTitle Title="About Us" />
                                

                            </div>

                        </div>

                        <Footer />
                    </div>

                </div>
            </>
        );
    }
}

export default AboutPage;