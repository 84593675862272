import React, { Component } from "react";
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from "cogo-toast";
import { registerAllModules } from "handsontable/registry";
//import "../../assets/css/custom.css";
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import PageTitle from "../home/PageTitle";

export default class SearchLead extends Component {
  //constructor lifecycle event
  constructor(props) {
    super(props);
    // register Handsontable's modulesy
    registerAllModules();
    this.state = {
      showModal: false,
      showDeleteWarnModal: false,
      showSearchModal: false,
      LeadData: [],
      TCData: [],
      SPLData: [],
      LeadAssignData: [],
      OriginCityData: [],
      LeadSourceTypeData: [],
      LeadStatusData: [],
      UserId: 0,
      UserRole: "",
      RedirectToLogin: false,

      enquiryId: 0,
      leadNo: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      message: "",
      travelDate: "",
      leadType: "",
      leadSourceId: 0,
      referrer: "",
      originCity: "",
      url: "",
      travelCircuitId: 0,
      specialistId: 0,
      ticketBooked: "",
      noOfGuests: "",
      hotelCategory: "",
      remarks: "",
      assignedId: 0,
      leadStatusId: "",
      isVoucherSent: false,
      isFinalPayReceived: false,
      isActive: true,
      disabledTC: false,
      disabledSpl: false,

      searchDateFrom: '',
      searchDateTo: '',
      searchTCId: 0,
      searchAssignedId: 0,
      searchLeadStatusId: 0,
      searchMobile: '',
      searchEmail: '',

    };
  }
  //componentDidMount lifecycle event
  componentDidMount() {
    window.scroll(0, 0);
    var _self = this;
    var userData = JSON.parse(LocalStorageHelper.getUser());
    if (userData !== null && userData.UserId > 0) {
      this.setState({
        UserId: userData.UserId,
        UserRole: userData.RoleName
      })
    }
    else {
      let winlocation = window.location.pathname;
      LocalStorageHelper.SetLastRedirectPath(winlocation);
      this.setState({ RedirectToLogin: true })
    }
    this.getAllLeadData();
    this.getAllLeadSourceTypes();
    this.getAllOriginCities();
    this.getAllTCData();
    this.getAllSPLData();
    this.getAllLeadStatus();
  }
  //Data Reload
  handleReload = () => this.getAllLeadData();
  //Reset all input fields into the modal
  resetInputFields = () => {
    this.setState({
      showModal: false,
      showDeleteWarnModal: false,
      showSearchModal: false,
      enquiryId: 0,
      leadNo: "",
      firstName: "",
      lastName: "",
      mobile: "",
      email: "",
      message: "",
      travelDate: "",
      leadType: "",
      leadSourceId: 0,
      referrer: "",
      originCity: "",
      url: "",
      travelCircuitId: 0,
      specialistId: 0,
      ticketBooked: "",
      noOfGuests: "",
      hotelCategory: "",
      remarks: "",
      assignedId: 0,
      leadStatusId: "",
      isVoucherSent: false,
      isFinalPayReceived: false,
      isActive: false,
      disabledTC: false,
      disabledSpl: false
    });
  }
  //Reset all input fields into the modal
  resetSearchInputFields = () => {
    this.setState({
      searchDateFrom: '',
      searchDateTo: '',
      searchTCId: 0,
      searchAssignedId: 0,
      searchLeadStatusId: 0,
      searchMobile: '',
      searchEmail: '',
      showSearchModal: false,
    });
  }
  //Open modal
  openModal = () => this.setState({ showModal: true });
  //Close modal
  closeModal = () => this.setState({ showModal: false });
  //Open Delete warning modal
  openWarnModal = () => this.setState({ showDeleteWarnModal: true });
  //Close Delete warning modal
  closeWarnModal = () => this.setState({ showDeleteWarnModal: false });
  //Open Search modal
  openSearchModal = () => this.setState({ showSearchModal: true });
  //Close Search modal
  closeSearchModal = () => this.setState({ showSearchModal: false });
  //On Change Handler
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //On Change Search Handler
  handleChangeSearch = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //Get all Lead Data
  getAllLeadData = () => {
    let param = {
      EnqueryId: this.state.enquiryId,
      Mobile: this.state.searchMobile,
      Email: this.state.searchEmail,
      StartDate: this.state.searchDateFrom,
      EndDate: this.state.searchDateTo,
      TravelCircuitId: this.state.searchTCId,
      AssignedId: this.state.searchAssignedId,
      LeadStatusId: this.state.searchLeadStatusId,
    };

    axios
      .post(ApiURL.GetAllLeadData, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          this.setState({ LeadData: dataResult });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all Lead Data ById
  getLeadDataById = (enquiryId) => {
    var self = this;
    axios
      .get(ApiURL.GetLeadDataById(enquiryId))
      .then((response) => {
        console.log(response.data);
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataRes = respData.DataResult;
          console.log(dataRes);
          if (dataRes !== null) {
            this.setState({
              enquiryId: dataRes.EnquiryId,
              leadNo: dataRes.EnquiryNo,
              firstName: dataRes.FirstName,
              lastName: dataRes.LastName,
              mobile: dataRes.Mobile,
              email: dataRes.Email,
              travelDate: dataRes.TravelDate,
              message: dataRes.Message,
              leadType: dataRes.LeadType,
              leadSourceId: dataRes.LeadSourceId,
              referrer: dataRes.Referrer,
              travelCircuitId: dataRes.TravelCircuitId,
              specialistId: dataRes.SpecialistId,
              url: dataRes.URL,
              originCity: dataRes.OriginCity,
              ticketBooked: dataRes.TicketBooked,
              noOfGuests: dataRes.NoOfGuests,
              hotelCategory: dataRes.HotelCategory,
              remarks: dataRes.Remarks,
              assignedId: dataRes.AssignedId,
              leadStatusId: dataRes.LeadStatusId,
              isVoucherSent: dataRes.IsVoucherSent,
              isFinalPayReceived: dataRes.IsFinalPayReceived,
              isActive: dataRes.IsActive,
              showModal: true,
              disabledTC: true,
              disabledSpl: true
            });
          }
        }
        else if (respData.StatusCode === 400) {
          cogoToast.error("Bad Request ! Please Try Again.", { position: "bottom-center" });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all Travel Circuit data
  getAllTCData = () => {
    axios
      .get(ApiURL.GetAllTC)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          var travelCircuit = [{ TravelCircuitId: 0, TravelCircuitName: "Travel Circuit" }];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              travelCircuit.push({
                TravelCircuitId: dataResult[i].TravelCircuitId,
                TravelCircuitName: dataResult[i].TravelCircuitName,
              });
            }
          }
          this.setState({ TCData: travelCircuit });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all Specialist data
  getAllSPLData = () => {
    axios
      .get(ApiURL.GetAllSPL)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          // console.log("dataResult: ", dataResult);
          var specialists = [{ SpecialistId: 0, SpecialistName: "Specialist Name" }];
          var leadAssignee = [{ SpecialistId: 0, SpecialistName: "Lead Assigned" }];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              specialists.push({
                SpecialistId: dataResult[i].SpecialistId,
                SpecialistName: dataResult[i].SpecialistName,
              });

              leadAssignee.push({
                SpecialistId: dataResult[i].SpecialistId,
                SpecialistName: dataResult[i].SpecialistName,
              })
            }
          }
          this.setState({
            SPLData: specialists,
            LeadAssignData: leadAssignee
          });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", {
            position: "bottom-center",
          });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", {
          position: "bottom-center",
        });
      });
  }
  //Get all Origin Cities
  getAllOriginCities = () => {
    axios
      .get(ApiURL.GetAllOriginCities)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          var originCities = [{ CityId: 0, CityName: "Origin City" }];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              originCities.push({
                CityId: dataResult[i].CityId,
                CityName: dataResult[i].CityName,
              });
            }
          }
          this.setState({ OriginCityData: originCities });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all Origin Cities
  getAllLeadSourceTypes = () => {
    axios
      .get(ApiURL.GetLeadSrcTypes)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          var leadSourceTypes = [{ LeadSourceId: 0, LeadSource: "Lead Source" }];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              leadSourceTypes.push({
                LeadSourceId: dataResult[i].LeadSourceId,
                LeadSource: dataResult[i].LeadSource,
              });
            }
          }
          this.setState({ LeadSourceTypeData: leadSourceTypes });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Get all Origin Cities
  getAllLeadStatus = () => {
    axios
      .get(ApiURL.GetLeadStatus)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          var dataResult = respData.DataResult;
          var leadStatus = [{ LeadStatusId: 0, LeadStatusDescription: "Lead Status" }];
          if (dataResult.length > 0) {
            for (var i = 0; i < dataResult.length; i++) {
              leadStatus.push({
                LeadStatusId: dataResult[i].LeadStatusId,
                LeadStatusDescription: dataResult[i].LeadStatusDescription,
              });
            }
          }
          this.setState({ LeadStatusData: leadStatus });
        } else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Add new Lead
  addNewLead = () => {
    this.resetInputFields()
    this.openModal()
  }
  //Save new/existing Lead Data
  handleSubmit = (e) => {
    e.preventDefault();
    let param = {
      enquiryId: this.state.enquiryId,
      leadNo: this.state.leadNo,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      mobile: this.state.mobile,
      email: this.state.email,
      travelDate: this.state.travelDate,
      message: this.state.message,
      leadType: this.state.leadType,
      leadSourceId: this.state.leadSourceId,
      referrer: this.state.referrer,
      travelCircuitId: this.state.travelCircuitId,
      specialistId: this.state.specialistId,
      url: this.state.url,
      originCity: this.state.originCity,
      ticketBooked: this.state.ticketBooked,
      noOfGuests: this.state.noOfGuests,
      hotelCategory: this.state.hotelCategory,
      remarks: this.state.remarks,
      assignedId: this.state.assignedId,
      leadStatusId: this.state.leadStatusId,
      isVoucherSent: this.state.isVoucherSent,
      isFinalPayReceived: this.state.isFinalPayReceived,
      isActive: this.state.isActive,
      CreatedBy: this.state.UserId,
      UpdatedBy: this.state.UserId
    };
    //console.log(param);
    axios
      .post(ApiURL.SaveLeadData, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success(respData.Message, { position: "bottom-center" });
          this.resetInputFields();
          this.getAllLeadData();
        }
        else if (respData.StatusCode === 300 || respData.StatusCode === 400 || respData.StatusCode === 404) {
          cogoToast.warn(respData.Message, { position: "bottom-center" });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Delete existing Lead Data
  handleDelete = () => {
    console.log(this.state.enquiryId);
    let param = {
      enquiryId: this.state.enquiryId,
    };
    axios
      .post(ApiURL.DeleteLeadData, param)
      .then((response) => {
        let respData = response.data;
        if (respData.StatusCode === 200) {
          cogoToast.success(respData.Message, { position: "bottom-center" });
          this.resetInputFields();
          this.getAllLeadData();
        }
        else if (respData.StatusCode === 400) {
          cogoToast.warn(respData.Message, { position: "bottom-center" });
        }
        else if (respData.StatusCode === 500) {
          cogoToast.error("Internal Server Error ! Please Try Again.", { position: "bottom-center" });
        }
      })
      .catch((error) => {
        cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      });
  }
  //Search Lead Data
  handleSearch = () => {
    this.resetSearchInputFields();
    this.getAllLeadData();
  }  

  downloadExcelData = async () => {
    try {
        const excelUrl = 'https://example.com/excel-data.xlsx'; // Replace with the actual URL of the Excel file

        const response = await axios.get(ApiURL.DownloadExcelData, {
            responseType: 'blob' // Set responseType to 'blob' for binary data
        });

        console.log(response.data);
        // Create a blob object from the response data
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a temporary URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'excel-data.xlsx'); // Specify the filename for download
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up: remove the link and revoke the URL
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        console.log('Excel data downloaded successfully.');
    } catch (error) {
        console.error('Error downloading Excel data:', error);
    }
};

  //render lifecycle event.
  render() {
    var self = this;
    const {
      showModal,
      showDeleteWarnModal,
      showSearchModal,
      LeadData,
      TCData,
      SPLData,
      LeadAssignData,
      OriginCityData,
      LeadSourceTypeData,
      LeadStatusData,
      enquiryId,
      travelCircuitId,
      specialistId,
      leadSourceId,
      assignedId,
      isActive
    } = self.state;


    const searchLeadListView = LeadData.map((sl, i) => (
      <tr key={i}>
        <td data-title="Select" className="text-center">
          <button
            className="btn btn-primary btn-sm text-white"
            style={{ height: "30px", width: "30px" }}
            onClick={() => self.getLeadDataById(sl.EnquiryId)}
            title="Select">
            <i className="fa-solid fa-pen-to-square" ></i>
          </button>
        </td>
        <td className="text-center" data-title="Entry Date">{sl.CreatedDate}</td>
        <td data-title="Full Name">{sl.FullName}</td>
        <td className="text-center" data-title="Mobile">+91 {sl.Mobile}</td>
        <td data-title="Email">{sl.Email}</td>
        <td className="text-center" data-title="Travel Circuit">{sl.TravelCircuitName}</td>
        <td className="text-center" data-title="Status">{sl.LeadStatusDescription}</td>      
        <td data-title="Assigned">{sl.AssignedName}</td>  
        <td data-title="URL"><a href={sl.URL} target="__blank">{sl.URL}</a></td>
      </tr>
    ));

    return (
      <>
        {/* ------Start main content ------- */}
        <div className="container-fluid ">
          <div className="card px-1 py-1">
            <div>
              <div className="col-md-12 py-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <PageTitle Title="Manage Search Lead" />
                  </div>
                  <div >
                    <div className="d-flex flex-row-reverse">
                      <button
                        type="button"
                        /* onClick={this.downloadExcelData} */
                        className="btn btn-info px-3 mx-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Download Excel"
                      >
                        <i className="fas fa-file-excel"></i>
                      </button>

                      <button
                        type="button"
                        onClick={this.openSearchModal}
                        className="btn btn-warning px-3 mx-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Search"
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </button>

                      <button
                        type="button"
                        onClick={this.handleReload}
                        className="btn btn-info px-3 mx-1"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Refresh"
                      >
                        <i className="fa-solid fa-arrows-rotate"></i>
                      </button>

                      <button
                        onClick={this.addNewLead}
                        className="btn btn-primary px-3 mx-1 cursor-pointer"
                        data-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Add"
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* grid table */}
          <div id="no-more-tables" className="tableContainer">
            <table className="col-md-12 table table-bordered table-striped">
              <thead className="cf bg-primary text-white">
                <tr>
                  <th className="text-center" style={{ maxWidth: "30px" }}>Select</th>
                  <th className="text-center" style={{ minWidth: "130px" }}>Entry Date</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>Customer Name</th>
                  <th className="text-center" style={{ minWidth: "130px" }}>Mobile</th>
                  <th className="text-center" style={{ minWidth: "140px" }}>Email</th>
                  <th className="text-center" style={{ minWidth: "150px" }}>Travel Circuit</th>
                  <th className="text-center" style={{ minWidth: "130px" }}>Status</th>
                  <th className="text-center" style={{ minWidth: "160px" }}>Assigned</th>
                  <th className="text-center" style={{ minWidth: "130px" }}>URL</th>
                </tr>
              </thead>
              <tbody>{searchLeadListView}</tbody>
            </table>
          </div>

        </div>

        {/* <!----------------- modal section----------------> */}
        <div
          className={
            showModal === true ? "modal show-modal  modalWrapper" : "modal"
          }
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title ">
                  {enquiryId <= 0 ? `Add Search Lead` : `Update Search Lead`}
                </h1>
                <i className="fa-regular fa-circle-xmark text-danger" data-bs-dismiss="modal" onClick={this.closeModal}></i>
              </div>
              <div className="modal-body">
                <div className="row  mx-3 mb-3">
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Lead No"
                        name="leadNo"
                        value={this.state.leadNo}
                        onChange={this.handleChange}
                        readOnly
                      />
                      <label htmlFor="leadNo">Lead No</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="firstName">First Name</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Last Name"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="lastName">Last Name</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        name="mobile"
                        value={this.state.mobile}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="mobile">Mobile</label>
                    </div>
                  </div>
                </div>
                <div className="row  mx-3 mb-3">
                  <div className="col-sm-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        required={true}
                      />
                      <label htmlFor="email">Email</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="date"
                        id="travelDate"
                        name="travelDate"
                        className="form-control"
                        value={this.state.travelDate}
                        onChange={this.handleChange}
                        placeholder="dd-mm-yyyy"
                      />
                      <label htmlFor="travelDate">Travel Date</label>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Lead Type"
                        name="leadType"
                        value={this.state.leadType}
                        onChange={this.handleChange}
                        readOnly
                      />
                      <label htmlFor="leadType">Lead Type</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="leadSourceId"
                      value={this.state.leadSourceId}
                      onChange={this.handleChange}
                    >
                      {LeadSourceTypeData.map((item, index) => (
                        <option key={index} value={item.LeadSourceId}>{item.LeadSource}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="originCity"
                      value={this.state.originCity}
                      onChange={this.handleChange}
                    >
                      {OriginCityData.map((item, index) => (
                        <option key={index} value={item.CityName}>{item.CityName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="travelCircuitId"
                      value={this.state.travelCircuitId}
                      onChange={this.handleChange}
                      required={true}
                      disabled={this.state.disabledTC}
                      readOnly
                    >
                      {TCData.map((item, index) => (
                        <option key={index} value={item.TravelCircuitId}>{item.TravelCircuitName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="specialistId"
                      value={this.state.specialistId}
                      onChange={this.handleChange}
                      disabled={this.state.disabledSpl}
                      readOnly
                    >
                      {SPLData.map((item, index) => (
                        <option key={index} value={item.SpecialistId}>{item.SpecialistName}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-12">
                    <div className="form-floating">
                      <textarea
                        row="2"
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        value={this.state.message}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="message">Message</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="referrer"
                        placeholder="Referrer"
                        value={this.state.referrer}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="referrer">Referrer</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-12">
                    <div className="form-floating">
                      <input
                        className="form-control"
                        placeholder="URL"
                        name="url"
                        value={this.state.url}
                        onChange={this.handleChange}
                      />
                      <label htmlFor="url">URL</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="ticketBooked"
                      value={this.state.ticketBooked}
                      onChange={this.handleChange}
                    >
                      <option value="">Ticket Booked</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                      <option value="Will do soon">Will do soon</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="noOfGuests"
                      value={this.state.noOfGuests}
                      onChange={this.handleChange}
                    >
                      <option value="">No. Of Guests</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="L7">7</option>
                      <option value="8">8</option>
                      <option value="More than 8">More than 8</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="hotelCategory"
                      value={this.state.hotelCategory}
                      onChange={this.handleChange}
                    >
                      <option value="">Hotel Category</option>
                      <option value="5 Star">5 Star</option>
                      <option value="4 Star">4 Star</option>
                      <option value="3 Star">3 Star</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="assignedId"
                      value={this.state.assignedId}
                      onChange={this.handleChange}
                    >
                      {LeadAssignData.map((item, index) => (
                        <option key={index} value={item.SpecialistId}>
                          {item.SpecialistName}
                        </option>
                      ))}
                    </select>

                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Remarks"
                        name="remarks"
                        value={this.state.remarks}
                        onChange={this.handleChange}
                        required={true}
                      />
                      <label htmlFor="remarks">Remarks</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-3 my-3">
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="leadStatusId"
                      value={this.state.leadStatusId}
                      onChange={this.handleChange}
                    >
                      {LeadStatusData.map((item, index) => (
                        <option key={index} value={item.LeadStatusId}>{item.LeadStatusDescription}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="isVoucherSent"
                      value={this.state.isVoucherSent}
                      onChange={this.handleChange}
                    >
                      <option>Voucher Sent</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                  <div className="col-sm-3">
                    <select
                      className="form-control form-select"
                      name="isFinalPayReceived"
                      value={this.state.isFinalPayReceived}
                      onChange={this.handleChange}
                    >
                      <option>Final Payment Received</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="modal-footer my-3">
                <button type="submit" className="btn btn-success button-box-shadow" onClick={this.handleSubmit}>
                  <i className="fa-solid fa-circle-check"></i> Save
                </button>
                {
                  this.state.UserRole === "admin" &&
                  <button
                    type="button"
                    className={`btn btn-danger button-box-shadow curser-pointer ${enquiryId > 0 ? "" : "disabled"}`}
                    onClick={() => { enquiryId > 0 && this.openWarnModal(); }}>
                    <i className="fa-solid fa-trash-can"></i> Delete
                  </button>
                }
                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow"
                  data-bs-dismiss="modal"
                  onClick={this.closeModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!------Delete Warning Modal--------> */}
        <div className={showDeleteWarnModal === true ? "modal show-modal " : "modal"}>
          <div className="modal-dialog modal-dialog-centered modal-md ">
            <div className="modal-content button-box-shadow">
              <div className="modal-body">
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fa-solid fa-triangle-exclamation"></i>&nbsp;
                  <div>
                    <strong>Are you sure</strong>, you want to  <strong>Delete </strong> it?
                  </div>
                </div>
              </div>
              <div className="modal-footer ">
                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow btn-sm"
                  data-bs-dismiss="modal"
                  onClick={this.closeWarnModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-danger button-box-shadow btn-sm"
                  onClick={this.handleDelete}
                >
                  <i className="fa-solid fa-trash-can"></i> Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <!------Search Modal--------> */}
        <div className={showSearchModal === true ? "modal show-modal " : "modal"}>
          <div className="modal-dialog modal-dialog-centered modal-md">
            <div className="modal-content button-box-shadow">
              <div className="modal-header">
                <h1 className="modal-title ">Search Lead</h1>
                <i className="fa-regular fa-circle-xmark text-danger" data-bs-dismiss="modal" onClick={this.closeSearchModal}></i>
              </div>
              <div className="modal-body">

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="searchDateFrom">Date From</label>
                    <input type="date" className="form-control input-sm" name="searchDateFrom" value={this.state.searchDateFrom} onChange={this.handleChangeSearch} />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="searchDateTo">Date To</label>
                    <input type="date" className="form-control input-sm" name="searchDateTo" value={this.state.searchDateTo} onChange={this.handleChangeSearch} />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="searchTC">Travel Circuit</label>
                    <select
                      className="form-control form-select"
                      name="searchTCId"
                      value={this.state.searchTCId}
                      onChange={this.handleChangeSearch}
                    >
                      {TCData.map((item, index) => (
                        <option key={index} value={item.TravelCircuitId}>{item.TravelCircuitName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="searchAssignedId">Assigned To</label>
                    <select
                      className="form-control form-select"
                      name="searchAssignedId"
                      value={this.state.searchAssignedId}
                      onChange={this.handleChangeSearch}
                    >
                      {LeadAssignData.map((item, index) => (
                        <option key={index} value={item.SpecialistId}>{item.SpecialistName}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-6">
                    <label htmlFor="searchLeadStatusId">Lead Status</label>
                    <select
                      className="form-control form-select"
                      name="searchLeadStatusId"
                      value={this.state.searchLeadStatusId}
                      onChange={this.handleChangeSearch}
                    >
                      {LeadStatusData.map((item, index) => (
                        <option key={index} value={item.LeadStatusId}>{item.LeadStatusDescription}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="searchMobile">Mobile No.</label>
                    <input
                      type="text"
                      className="form-control input-sm"
                      name="searchMobile"
                      placeholder="Enter Mobile No."
                      value={this.state.searchMobile}
                      onChange={this.handleChangeSearch} />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-md-12">
                    {/* <label htmlFor="searchDateTo">Email</label> */}
                    <input
                      type="text"
                      className="form-control input-sm"
                      name="searchEmail"
                      placeholder="Enter Email Id"
                      value={this.state.searchEmail}
                      onChange={this.handleChangeSearch} />
                  </div>
                </div>


              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary button-box-shadow btn-sm"
                  data-bs-dismiss="modal"
                  onClick={this.closeSearchModal}
                >
                  <i className="fa-solid fa-circle-xmark"></i> Cancel
                </button>

                <button
                  type="button"
                  className="btn btn-primary button-box-shadow btn-sm"
                  onClick={this.handleSearch}
                >
                  <i className="fa-solid fa-magnifying-glass"></i> Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
