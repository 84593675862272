import React, { Component } from 'react';
import Sidebar from "../../components/common/Sidebar";
import Topbar from "../../components/common/Topbar";
import PageTitle from "../../components/home/PageTitle";
import Footer from '../../components/common/Footer';
import SpecialList from '../../components/master/SpecialList';

class SpecialistPage extends Component {
    render() {
        return (
            <>
                <div id="wrapper">

                    <Sidebar />

                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">

                            <Topbar />

                            <div className="container-fluid">

                                <PageTitle Title="Manage Specialist" />
                                <SpecialList/>

                            </div>

                        </div>

                        <Footer />
                    </div>

                </div>
            </>
        );
    }
}

export default SpecialistPage;